import { Field, Formik } from 'formik';
import { isNull } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import uniqid from 'uniqid';
import useFetch from 'use-http';
import * as Yup from 'yup';
import { useCartDispatch, useCartState } from '../../context/CartContext';
import { useSettingsState } from '../../context/SettingsContext';
import Button from '../../shared/Button';
import Calendar from '../../shared/Calendar';
import Checkbox from '../../shared/Checkbox';
import Container from '../../shared/Container';
import FileUpload from '../../shared/FileUpload';
import Form from '../../shared/Form';
import Modal from '../../shared/Modal';
import { formatCurrency } from '../../shared/Money';
import Page from '../../shared/Page';
import Radio from '../../shared/Radio';
import Tabs from '../../shared/Tabs';
import TextField from '../../shared/TextField';
import Description from './Description';
import ServicePreparation from './ServicePreparation/ServicePreparation';
import moment from 'moment';
import { useEffectOnce } from 'react-use';
import { ReactComponent as TlkIcon } from '../../../assets/icons/tlk.svg';
import Questions from '../../../__mock__/Questions';
import TimeSlots from '../../../__mock__/TimeSlots';
import WorkplaceService from '../../../__mock__/WorkplaceService';
import { useUserDispatch, useUserState } from '../../context/UserContext';
import TimeSlotSessionStyled from '../../shared/TimeSlotSession/TimeSlotSession.styles';
import Styled from './ServicePreparation/ServicePreparation.styles';
import { exponeaCapture } from '../../hooks/exponeaCapture';

const USE_MOCKS = false;

const ServicePage = () => {
  const { t } = useTranslation();
  const { agreements, phoneNumber, shortPhoneNumber } = useSettingsState();
  const history = useHistory();
  const dispatch = useCartDispatch();
  const { workplaceId, serviceId, timeSlotId, isPaid } = useParams();
  const cartState = useCartState();
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [step, setStep] = useState(0);
  const tabsRef = useRef();
  const laboratoryMultipleServicesRef = useRef();
  const mrt3tPriceChangeDate = new Date(
    process.env.REACT_APP_3T_PRICE_CHANGE_DATE,
  );
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [filterStateRefresh, setFilterStateRefresh] = useState(1);
  const isDiscount = userState.discountCard;
  const [showEmptyCartMessage, setShowEmptyCartMessage] = useState(false);
  const [service, setService] = useState({});
  const [loading, setLoading] = useState(true);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isTimeSlotSelected, setTimeSlotSelected] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isCancelRegistration, setIsCancelRegistration] = useState(false);
  const [lastStep, setLastStep] = useState(undefined);
  const [fileUploadRequired, setFileUploadRequired] = useState(true);
  const [underAge, setUnderAge] = useState(false);
  const [patientName, setPatientName] = useState();
  const [patientSurname, setPatientSurname] = useState();
  const [childIdNumber, setChildIdNumber] = useState();
  const [isCancelModalClicked, setIsCancelModalClicked] = useState(false);
  const [otherServices, setOtherServices] = useState();
  const [isExpressCare, setIsExpressCare] = useState(false);
  const [showExpressCareModal, setShowExpressCareModal] = useState(false);
  const [showExpressCareTypes, setShowExpressCareTypes] = useState(false);
  const [expressCareType, setExpressCareType] = useState();
  const [stop, setStop] = useState(false);
  const [activeTabs, setActiveTabs] = useState([]);
  const [
    showSelectMorePsdfServicesButton,
    setShowSelectMorePsdfServicesButton,
  ] = useState(false);
  const [laboratoryMultipleServices, setLaboratoryMultipleServices] =
    useState();
  const workplacesFor3T =
    process.env.REACT_APP_3T_WORKPLACE_EXTERNAL_ID_ARRAY.split(',');
  const [previousRegistrationService, setPreviousRegistrationService] =
    useState();

  const { request: serviceRequest, response: serviceGetResponse } =
    useFetch('/Services');
  const formRef = useRef();
  const { request: timeRequest, response: timeResponse } =
    useFetch(`/TimeSlots`);

  const { request: fileRequest } = useFetch(`/Files`);

  const { post: postOtherServices, response: postOtherServicesResponse } =
    useFetch(`/Services/OtherServices`);

  const { get: getTimeslotById } = useFetch(`/TimeSlots`);

  const {
    post: cancelRegistrationRequestSend,
    response: cancelRegistrationResponse,
  } = useFetch(`/RegistrationManagement/Registrations/Cancel`);

  let initialValuesBase = {
    workplaceId,
    serviceId,
    timeSlotId: undefined,
    timeSlot: {},
    patientComment: '',
    childName: '',
    childSurname: '',
    childIdNumber: '',
    childBirthDate: null,
    serviceQuestionId: null,
    contraindicationQuestionIds: [],
    primaryVisit: true,
    otherPerson: false,
    eHealth: false,
    termsAndConditions: false,
    files: [],
    fileIds: [],
  };
  let initialValues = initialValuesBase;
  const serviceTypeExternalId =
    process.env.REACT_APP_SERVICE_TYPE_ID_EXTERNALID_EXCLUDE;
  const serviceTypeIdSearchByKeyword =
    process.env.REACT_APP_SERVICE_TYPE_ID_REQUIRE_PSDF_FILE_BY_SEARCH_KEYWORD;

  const sgkExternalServiceTypeId = process.env.REACT_APP_SGK_SERVICE_TYPE_ID;

  const mrtCategory = process.env.REACT_APP_MRT_CATEGORY;

  const externalServiceTypeIdForGroupedService =
    process.env.REACT_APP_LABEXTERNALID;

  const expressCareServiceTypeId =
    process.env.REACT_APP_EXPRESS_CARE_SERVICE_TYPE_ID;

  const gastroenterologyServiceTypeExternalId =
    process.env.REACT_APP_GASTROENTEROLOGY_SERVICE_TYPE_EXTERNAL_ID;

  const SGSpecialtyExternalId = process.env.REACT_APP_SG_SPECIALTY_EXTERNAL_ID;

  const servicesPageQueryUrl = history.location.state?.backUrl;

  useEffect(() => {
    async function fetchData() {
      await postOtherServices('', {
        serviceId: serviceId,
        workplaceId: workplaceId,
      }).then((res) => {
        setOtherServices(res);
      });
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (shouldUseAdditionalServicesByResearchArea()) {
      if (
        !!selectedTimeSlot &&
        !!selectedTimeSlot.researchAreaId &&
        selectedTimeSlot.isPaid
      ) {
        const params = new URLSearchParams({
          serviceId: service.serviceId,
          researchAreaId: selectedTimeSlot.researchAreaId,
          timeSlotDateTime: selectedTimeSlot.startDate,
          departmentExternalId: service.departmentExternalId,
        });
        const url = `ResearchAreaDayOfWeekAdditionalServices?${params.toString()}`;
        serviceRequest.get(url).then((response) => {
          setService((prevServices) => ({
            ...prevServices,
            additionalServices: [
              ...response,
              ...(service.mandatoryOtherServices ?? []),
            ],
          }));
        });
      } else {
        setService((prevServices) => ({
          ...prevServices,
          additionalServices: [],
        }));
      }
    }
  }, [selectedTimeSlot]);

  useEffect(() => {
    if (
      history.location?.state?.laboratoryMultipleServices?.length > 0 &&
      cartState.selectedTimeslot
    ) {
      setLaboratoryMultipleServices(
        history.location.state.laboratoryMultipleServices,
      );
    } else if (
      history.location?.state?.registrationServices?.[0]
        ?.laboratoryMultipleServices?.length > 0 &&
      cartState.selectedTimeslot
    ) {
      setLaboratoryMultipleServices([
        service,
        ...history.location.state.registrationServices[0]
          .laboratoryMultipleServices,
      ]);
    }
  }, [cartState.selectedTimeslot, history]);

  useEffect(() => {
    if (USE_MOCKS) {
      setService({ ...WorkplaceService, ...Questions });
      setLoading(false);
    } else {
      Promise.all([
        serviceRequest.get(`WorkplaceServices/${workplaceId}/${serviceId}`),
        serviceRequest.get(`/${workplaceId}/${serviceId}/Questions`),
      ]).then(([s, q]) => {
        if (s.errors) {
          history.push('/');
        } else {
          if (!s) {
            history.push('/');
          } else {
            setService({ ...s, ...q });
            setLoading(false);
          }
        }
      });
    }
  }, [serviceId, serviceRequest, workplaceId]);

  useEffect(() => {
    // `/services/${service.categoryId}/${service.serviceTypeId}?workplaceId=${laboratoryServices.laboratoryMultipleServices[0].workplaceId}` +
    // (!isPaid ? '&isPsdfId=true' : ''),
    if (service.serviceTypeId) {
      serviceRequest
        .get(
          `WorkplaceServices?workplaceId=${workplaceId}&serviceTypeId=${
            service.serviceTypeId
          }&isPsdfId=${!isPaid ? 'true' : ''}`,
        )
        .then((response) => {
          if (response && response.services?.length > 0) {
            if (
              response.services &&
              response.services.some((x) => x.nearestPsdfDate != null)
            ) {
              setShowSelectMorePsdfServicesButton(true);
            }
          } else {
            setShowSelectMorePsdfServicesButton(false);
          }
        });
    }
  }, [service]);

  const [isHalfMrt, setIsHalfMrt] = useState(
    service.cityTitle === 'Šiauliai' &&
      service.serviceTitle.split(' ').includes('1.5T') &&
      service.serviceTypeExternalId == process.env.REACT_APP_MRT_CATEGORY &&
      !(service.serviceTitle.substring(0, 9) === 'Viso kūno'),
  );

  useEffect(() => {
    setIsHalfMrt(
      service.cityTitle === 'Šiauliai' &&
        service.serviceTitle.split(' ').includes('1.5T') &&
        service.serviceTypeExternalId == process.env.REACT_APP_MRT_CATEGORY &&
        !(service.serviceTitle.substring(0, 9) === 'Viso kūno'),
    );
  }, [service]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (cartState.leftToPaysera) {
      setIsCancelModalVisible(true);
    }
  }, [cartState.leftToPaysera]);

  useEffect(() => {
    if (service) {
      if (
        service.serviceTypeExternalId?.toString() ===
        expressCareServiceTypeId.toString()
      ) {
        setIsExpressCare(true);

        if (step === 0) {
          setShowExpressCareModal(true);
          setShowExpressCareTypes(true);
        }
      }

      const isService = !service.specialistId;
      exponeaCapture(
        [
          { name: 'category_1', value: service.serviceTypeTitle },
          {
            name: 'category_2',
            value: isService
              ? service.serviceTitle
              : service.specialistName + ' - ' + service.serviceTitle,
          },
          { name: 'product_id', value: service.serviceId },
          { name: 'category_id', value: service.categoryId },
        ],
        'view_item',
      );
    }
  }, [service]);

  useEffect(async () => {
    if (isCancelRegistration) {
      await cancelRegistrationRequestSend('', {
        cancelationToken: cartState.registrationId,
      });
      dispatch({
        type: 'RESET_ONLY_REGISTRATION_ID',
      });

      history.push('/payment/error');
    }
  }, [isCancelRegistration]);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (formRef.current) {
      if (cartState.selectedTimeslot && cartState.selectedTimeslot.timeSlotId) {
        formRef.current.values.timeSlot = cartState.selectedTimeslot;
        formRef.current.values.timeSlotId =
          cartState.selectedTimeslot?.timeSlotId;
      }
    }
  });

  useEffectOnce(() => {
    if (timeSlotId && !stop) {
      timeRequest.post('/Reservation', {
        timeSlotsIds: [timeSlotId],
        additionalData: `location:ServicePage.js useEffectOnce; historyLocation:${history.location.pathname}`,
        //for debuging purposes. Temporary po-706
      });

      getTimeslotById(`/${timeSlotId}/${serviceId}/${isPaid}`).then((ts) => {
        setTimeSlotSelected(true);
        formRef.current.values.timeSlot = ts;
        formRef.current.values.timeSlotId = timeSlotId;

        setSelectedTimeSlot(ts);
        dispatch({
          type: 'SET_TEMP_TIME_SLOT',
          timeSlotId,
        });

        dispatch({
          type: 'SET_SERVICE_DURATION',
          data: {
            serviceDuration: service.durationTime,
            timeSlotDate: ts.startDate,
          },
        });

        userDispatch({
          type: 'SET_TIMESLOT',
          timeSlotId,
        });
        userDispatch({
          type: 'SET_TIMESLOT_SERVICE_URL',
          data: {
            backUrl: `/order/${workplaceId}/${serviceId}`,
          },
        });

        dispatch({
          type: 'SET_TIMESLOT',
          selectedTimeslot: ts,
        });

        dispatch({
          type: 'SET_TIMER',
          date: new Date(),
        });
        setStop(true);
      });

      dispatch({
        type: 'REGISTRATION_FROM_WIDGET',
        fromWidget: false,
      });

      setStep(1);

      // changeStep({ values: initialValues, prev: true });
    }
  }, [
    timeRequest,
    step,
    setTimeSlotSelected,
    setSelectedTimeSlot,
    timeSlotId,
    service.durationTime,
    stop,
  ]);

  useEffect(() => {
    setLastStep(history.location.state?.lastStep);
    setUnderAge(history.location.state?.underAge);
    setPatientName(history.location.state?.patientName);
    setPatientSurname(history.location.state?.patientSurname);
    setChildIdNumber(history.location.state?.childIdNumber);
    setPreviousRegistrationService(() => {
      if (history.location.state?.registrationServices) {
        return history.location.state?.registrationServices[0];
      }
    });
  }, [
    history,
    history.location.state?.lastStep,
    history.location.state?.registrationServices,
    history.location.state?.underAge,
    setUnderAge,
    setLastStep,
    initialValuesBase,
  ]);

  window.addEventListener('unload', function (e) {
    if (
      !history.location.state?.lastStep &&
      history.location.pathname !== '/checkout'
    ) {
      dispatch({
        type: 'RESET_CART',
      });
    }
  });

  useEffect(() => {
    if (!cartState.selectedTimeslot) {
      history.replace(`/order/${workplaceId}/${serviceId}`, {
        backUrl: servicesPageQueryUrl,
      });
      //setLaboratoryMultipleServices([]);
      dispatch({
        type: 'RESET_CART',
      });
    }
  }, [cartState.selectedTimeslot]);

  useEffect(() => {
    return () => {
      window.removeEventListener('unload', function (e) {
        if (!history.location.state?.lastStep) {
          dispatch({
            type: 'RESET_CART',
          });
        }
      });
    };
  }, [dispatch, history.location.pathname, history.location.state?.lastStep]);

  // useEffect(() => {
  //   return () => {
  //     if (history.location.pathname === '/selfservice') {
  //       dispatch({
  //         type: 'SET_BACK_URL',
  //         url: `/order/${workplaceId}/${serviceId}`,
  //       });
  //     } else if (
  //       history.location.pathname !== '/checkout' &&
  //       history.location.pathname !== `/order/${workplaceId}/${serviceId}` &&
  //       history.location.pathname !==
  //         `/order/${workplaceId}/${serviceId}/${timeSlotId}/${isPaid}`
  //     ) {
  //       dispatch({
  //         type: 'RESET_CART',
  //       });
  //     }
  //   };
  // }, [dispatch, history, isPaid, lastStep, serviceId, step, workplaceId]);

  useEffect(() => {
    return () => {
      if (history.location.pathname === '/selfservice') {
        dispatch({
          type: 'SET_BACK_URL',
          url: `/order/${workplaceId}/${serviceId}`,
        });
      } else if (
        history.location.pathname !== '/checkout' &&
        history.location.pathname + history.location.search !==
          `/services/${service.categoryId}/${service.serviceTypeId}?workplaceId=${workplaceId}` &&
        history.location.pathname + history.location.search !==
          `/services/${service.categoryId}/${service.serviceTypeId}?workplaceId=${workplaceId}&isPsdfId=true` &&
        history.location.pathname !== `/order/${workplaceId}/${serviceId}` &&
        history.location.pathname !==
          `/order/${workplaceId}/${serviceId}/${timeSlotId}/${isPaid}`
      ) {
        dispatch({
          type: 'RESET_CART',
        });
      }
    };
  }, [
    dispatch,
    history,
    isPaid,
    lastStep,
    service.categoryId,
    service.serviceTypeId,
    serviceId,
    step,
    timeSlotId,
    workplaceId,
  ]);

  useEffect(() => {
    if (
      previousRegistrationService !== undefined &&
      previousRegistrationService !== null
    ) {
      fillDataFromPrevious(previousRegistrationService);
    }
  }, [previousRegistrationService]);

  async function fillDataFromPrevious(rs) {
    if (rs !== undefined && lastStep !== undefined) {
      setTimeSlotSelected(true);
      setSelectedTimeSlot(rs.timeSlot);
      setStep(lastStep + 1);
      changeStep({ values: rs, prev: true });

      initialValues = rs;
      if (underAge) {
        initialValues.otherPerson = true;
        initialValues.childName = patientName;
        initialValues.childSurname = patientSurname;
        initialValues.childIdNumber = childIdNumber;
      }
    }
  }

  const isLaboratoryServices = () =>
    service.serviceTypeExternalId == externalServiceTypeIdForGroupedService;

  function refreshPage() {
    window.location.reload(false);
  }
  const saveFromValues = (val) => {
    const productCopy = Object.assign({}, val);

    const otherPersonProperties = [
      'childBirthDate',
      'childIdNumber',
      'childName',
      'childSurname',
      'otherPerson',
    ];

    if (!productCopy.otherPerson) {
      otherPersonProperties.forEach((key) => {
        delete productCopy[key];
      });
    }
    dispatch({
      type: 'ADD_TO_TEMP_CART',
      product: {
        ...productCopy,
        id: uniqid(),
      },
    });
  };

  const getTimeSlots = useCallback(
    (from, to, filter) => {
      if (USE_MOCKS) {
        return new Promise((resolve) => {
          resolve(TimeSlots);
        });
      } else {
        return timeRequest.get(
          `?WorkplaceId=${workplaceId}&DateFrom=${from}&DateTo=${to}&Filter=${filter}&serviceId=${serviceId}`,
        );
      }
    },
    [timeRequest, workplaceId],
  );

  const shouldUseAdditionalServicesByResearchArea = () => {
    if (!service || !service.serviceTypeExternalId) {
      return false;
    }
    return (
      gastroenterologyServiceTypeExternalId == service.serviceTypeExternalId
    );
  };

  function addToCart(product) {
    product = {
      ...product,
      expressCareType,
      activeTabs: activeTabs.map((tab) => tab.name),
    };
    const productCopy = Object.assign({}, product);

    const otherPersonProperties = [
      'childIdNumber',
      'childBirthDate',
      'childName',
      'childSurname',
      'otherPerson',
    ];

    if (!productCopy.otherPerson) {
      otherPersonProperties.forEach((key) => {
        delete productCopy[key];
      });
    }
    dispatch({
      type: 'ADD_TO_CART',
      product: {
        ...productCopy,
        id: uniqid(),
        laboratoryMultipleServices: laboratoryMultipleServices?.slice(1),
      },
    });

    dispatch({
      type: 'SET_STEP',
      step: step,
    });

    dispatch({
      type: 'SET_ORDER_URL',
      url: `/order/${workplaceId}/${serviceId}`,
    });

    history.push('/checkout', {
      backPath: `/order/${workplaceId}/${serviceId}`,
    });
  }

  const getAdditionalLaboratoryServices = (isPaid) => {
    let laboratoryServices = {
      values: formRef.current.values,
      backUrl: window.location.pathname,
      isPaidLaboratoryService: isPaid,
      laboratoryMultipleServices: laboratoryMultipleServicesRef.current ?? [
        service,
      ],
    };

    history.push(
      `/services/${service.categoryId}/${service.serviceTypeId}?workplaceId=${laboratoryServices.laboratoryMultipleServices[0].workplaceId}` +
        (!isPaid ? '&isPsdfId=true' : ''),
      laboratoryServices,
    );
  };

  async function changeStep({
    next,
    validateForm,
    values,
    prev,
    validate,
  } = {}) {
    setButtonLoading(true);
    const executeChange = () => {
      if (step === 1 && !next) {
        // if (cartState.selectedTimeslot.timeSlotId && cartState.fromWidget) {
        //   timeRequest.delete(
        //     `/Reservation/${cartState.selectedTimeslot.timeSlotId}`,
        //   );
        // }
        dispatch({
          type: 'SET_TEMP_TIME_SLOT',
          timeSlotId: undefined,
        });
        setLastStep(undefined);
        setPreviousRegistrationService(null);
        initialValues = initialValuesBase;
        values = initialValuesBase;
        setRefresh(true);
        history.push(history.location.pathname, {
          backUrl: servicesPageQueryUrl,
        });
      }
      setStep((prevStep) => prevStep + (next ? 1 : -1));
      setLastStep(step + (next ? 1 : -1));
      setButtonLoading(false);
    };

    let changeStatus = true;
    if (getTabsConfig()[step]?.onChange) {
      changeStatus = await getTabsConfig()[step].onChange(values);
    }

    if (changeStatus) {
      dispatch({
        type: 'SET_STEP',
        step: step + 1,
      });
      if (next) {
        if (!prev) {
          validateForm().then((errors) => {
            if (!Object.keys(errors).length) {
              executeChange();
            }
            setButtonLoading(false);
          });
        } else {
          executeChange();
          setButtonLoading(false);
        }
      } else {
        setButtonLoading(false);
        executeChange();
      }
    }
  }

  const AddMoreLaboratoryServicesButton = () => {
    if (!isLaboratoryServices()) return null;

    return (
      <>
        <Styled.AddMoreLaboratoryServicesButton>
          <Button
            size="large"
            disabled={isButtonLoading || !isTimeSlotSelected}
            onClick={() => getAdditionalLaboratoryServices(true)}
          >
            {showSelectMorePsdfServicesButton
              ? 'Pasirinkti papildomus mokamus laboratorinius tyrimus'
              : 'Pasirinkti papildomus laboratorinius tyrimus'}
          </Button>
        </Styled.AddMoreLaboratoryServicesButton>
        {showSelectMorePsdfServicesButton && (
          <Styled.AddMoreLaboratoryServicesButton>
            <Button
              size="large"
              disabled={isButtonLoading || !isTimeSlotSelected}
              onClick={() => getAdditionalLaboratoryServices(false)}
            >
              Pasirinkti papildomus PSDF kompensuojamus laboratorinius tyrimus
            </Button>
          </Styled.AddMoreLaboratoryServicesButton>
        )}
      </>
    );
  };

  const handleRemoveFromLaboratoryMultipleServices = (indexToRemove) => {
    setLaboratoryMultipleServices((prevArray) => {
      const newArray = prevArray.filter(
        (_, index) => index !== indexToRemove + 1,
      );
      return newArray;
    });
  };

  const isMrtWorkplace = () => {
    return (
      !!service?.workplaceExternalId &&
      workplacesFor3T.includes(service.workplaceExternalId.toString())
    );
  };

  useEffect(() => {
    laboratoryMultipleServicesRef.current = laboratoryMultipleServices;
  }, [laboratoryMultipleServices]);

  const expressCareTabConfig = useCallback(() => {
    return [
      {
        name: t('registration.expressCare.tabName'),
        renderCondition: isExpressCare,
        schema: () => {
          return {
            serviceQuestionId: Yup.string()
              .nullable()
              .test(
                'serviceQuestionId',
                'Laukelis privalomas',
                function (value, { createError }) {
                  if (
                    !(
                      service.serviceTypeExternalId ==
                        sgkExternalServiceTypeId &&
                      !service.isVilniusAffideaClinic &&
                      selectedTimeSlot?.isPaid
                    )
                  ) {
                    return true;
                  } else if (value === undefined || value === null) {
                    return false;
                  }
                  return true;
                },
              ),
            contraindicationQuestionIds: Yup.array().max(
              0,
              t('validation.contraindicationQuestion', {
                phoneNumber,
                shortPhoneNumber,
              }),
            ),
          };
        },
        content: () => (
          <>
            <Styled.Table>
              <tbody>
                <tr>
                  <td>
                    <strong>Viršutinių galūnių sužalojimai</strong>
                  </td>
                  <td>
                    <Button
                      size="medium"
                      onClick={() => {
                        setExpressCareType(1);
                        setShowExpressCareTypes(false);
                      }}
                    >
                      Tęsti
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul>
                      <li>Kas yra viršutinių galūnių sužalojimai?</li>
                      <li>
                        Kai sužalojamas petys, kaklas, žastas, alkūnė, riešas,
                        nykštys ir pirštai.
                      </li>
                      <li>Kokiais atvejais galima kreiptis į Express Care®?</li>
                      <li>
                        Uždari lūžiai, išnirimai, patempimai, patinimai, sąnarių
                        ir raumenų skausmas, kiti minkštųjų audinių pažeidimai.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Apatinių galūnių sužalojimai</strong>
                  </td>{' '}
                  <td>
                    <Button
                      size="medium"
                      onClick={() => {
                        setExpressCareType(2);
                        setShowExpressCareTypes(false);
                      }}
                    >
                      Tęsti
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul>
                      <li> Kas yra apatinių galūnių sužalojimai?</li>
                      <li>
                        Kai trauma apima blauzdos, kelio, kulkšnies, pėdos ar
                        pirštų sritis.
                      </li>
                      <li>
                        Į apatinių galūnių sužalojimus įeina ir raumenų
                        patempimai, lūžiai ir pėdų skausmai.
                      </li>
                      <li>Kokiais atvejais galima kreiptis į Express Care®?</li>
                      <li>
                        Uždari lūžiai, išnirimai, patempimai, patinimas,
                        minkštųjų audinių pažeidimai, sąnarių ir raumenų
                        skausmas.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Žaizdos ir jų priežiūra</strong>
                  </td>
                  <td>
                    <Button
                      size="medium"
                      onClick={() => {
                        setExpressCareType(3);
                        setShowExpressCareTypes(false);
                      }}
                    >
                      Tęsti
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul>
                      <li>
                        Teikiama pagalba iškart po sužeidimo (nubrozdinimai,
                        įpjovimai, įtrūkimai) ir žaizdų priežiūra.
                      </li>
                      <li>
                        Klinikoje atliekamas žaizdų valymas, susiuvimas,
                        perrišimas. Taip pat galima kreiptis, jei įtariate
                        infekciją.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Lengvi galvos sužalojimai</strong>
                  </td>
                  <td>
                    <Button
                      size="medium"
                      onClick={() => {
                        setExpressCareType(4);
                        setShowExpressCareTypes(false);
                      }}
                    >
                      Tęsti
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul>
                      <li>
                        Į Express Care® galima kreiptis patyrus veido, ausų ar
                        nosies traumas.
                      </li>
                      <li>
                        Priimami tik sąmoningi pacientai, kurie po galvos
                        traumos nepatyrė sąmonės praradimo, amnezijos, vėmimo ar
                        galūnių silpnumo ir kurių judrumas nepasikeitė.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Kiti atvejai</strong>
                  </td>{' '}
                  <td>
                    <Button
                      size="medium"
                      onClick={() => {
                        setExpressCareType(5);
                        setShowExpressCareTypes(false);
                      }}
                    >
                      Tęsti
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul>
                      <li>Nedideli nudegimai</li>
                      <li>Gyvūnų ir vabzdžių įkandimai</li>
                      <li>Svetimkūnių pašalinimas iš ausų ir nosies</li>
                      <li>Infekcija</li>
                      <li>Bėrimas ar odos infekcijos</li>
                      <li>Gerklės skausmas</li>
                      <li>Akių ir ausų problemos</li>
                      <li>Lengvos alerginės reakcijos</li>
                      <li>Dažnas ir skausmingas šlapinimasis (moterims)</li>
                      <li> Nestiprus kraujavimas iš nosies</li>
                      <li> Nestiprūs galvos skausmai</li>
                      <li> Netrauminės kilmės nugaros skausmai</li>
                      <li> Bėrimai</li>
                      <li> Karščiavimas</li>
                      <li> Dehidratacija, skysčių trūkumas</li>
                      <li>
                        Viršutinių kvėpavimo takų infekcijos (kosulys,
                        karščiavimas, gerklės skausmas)
                      </li>
                      <li>
                        Akių problemos, kai nėra regos sutrikimo (pūliavimas,
                        niežulys, miežis)
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </Styled.Table>
          </>
        ),
      },
    ];
  }, []);

  const getTabsConfig = useCallback(() => {
    const requiredMessage = t('validation.requiredField');
    const isFileRequiredForService = () => {
      if (Object.keys(service).length !== 0) {
        if (
          !selectedTimeSlot?.isPaid &&
          serviceTypeIdSearchByKeyword ===
            service?.serviceTypeExternalId.toString() &&
          (service?.serviceTitle.toLowerCase().includes('šeimos') ||
            (service.specialty &&
              service.specialty.toLowerCase().includes('šeimos')))
        ) {
          return false;
        } else if (
          !selectedTimeSlot?.isPaid &&
          serviceTypeExternalId !== service?.serviceTypeExternalId.toString()
        ) {
          return true;
        }
        return false;
      }
      return false;
    };
    return [
      {
        name: t('registration.time.tabName'),
        renderCondition: true,
        schema: () => {
          return {
            timeSlotId: Yup.string().required(requiredMessage),
          };
        },
        content: (errors, values, setFieldValue, handleChange) => (
          <div>
            <Styled.TimeSlotsFilter>
              {service.psdf && service.nearestPsdfDate && (
                <Radio
                  style={{
                    border:
                      selectedFilter.split('?')[0] === 'all'
                        ? '2px #4181d0 solid'
                        : '1px lightgrey solid',
                    borderRadius: '40px',
                    padding:
                      window.innerWidth > 978
                        ? '8px 10px 6px 10px'
                        : '8px 10px 8px 10px',
                    backgroundColor: '#f1f5f7',
                    marginBottom: '5px',
                    minWidth: window.innerWidth > 978 ? '205px' : '170px',
                  }}
                  name={'filtersGroup'}
                  value={'all'}
                  checked={selectedFilter.split('?')[0] === 'all'}
                  onChange={(value) => {
                    if (service.upcomingVisit) {
                      setSelectedFilter(value + '?' + service.upcomingVisit);
                    } else {
                      setSelectedFilter(value + '?' + new Date());
                    }
                    setFilterStateRefresh((p) => p + 1);
                  }}
                >
                  Visi galimi laikai
                </Radio>
              )}

              {service.extraPrice !== 0 ? (
                <Radio
                  style={{
                    border:
                      selectedFilter.split('?')[0] === 'paid'
                        ? '2px #4181d0 solid'
                        : '1px lightgrey solid',
                    borderRadius: '40px',
                    padding:
                      window.innerWidth > 978
                        ? '8px 10px 6px 10px'
                        : '8px 10px 8px 10px',
                    background:
                      window.innerWidth > 978
                        ? 'linear-gradient(to right, #a2cbff 51%, #7447E6 49%)'
                        : 'linear-gradient(to right, #a2cbff 53%, #7447E6 47%)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    overflow: 'hidden',
                    marginBottom: '5px',
                  }}
                  name={'filtersGroup'}
                  value={'paid'}
                  onChange={(value) => {
                    if (
                      (service.nearestPaidDate > service.nearestPsdfDate ||
                        service.nearestPaidDate === null) &&
                      service.nearestPsdfDate
                    ) {
                      setSelectedFilter(value + '?' + service.nearestPsdfDate);
                    } else if (service.nearestPaidDate) {
                      setSelectedFilter(value + '?' + service.nearestPaidDate);
                    } else {
                      setSelectedFilter(value + '?' + new Date());
                    }
                    setFilterStateRefresh((p) => p + 1);
                  }}
                >
                  <span
                    style={{
                      flex: 1,
                      textAlign: 'center',
                      paddingRight: '20px',
                    }}
                  >
                    Mokami laikai
                  </span>
                  <span
                    style={{
                      flex: 1,
                      textAlign: 'center',
                      paddingLeft: '0',
                      color: 'white',
                    }}
                  >
                    Papildomi mokami
                  </span>
                </Radio>
              ) : (
                <Radio
                  style={{
                    border:
                      selectedFilter.split('?')[0] === 'paid'
                        ? '2px #4181d0 solid'
                        : '1px lightgrey solid',
                    borderRadius: '40px',
                    padding:
                      window.innerWidth > 978
                        ? '8px 10px 6px 10px'
                        : '8px 10px 8px 10px',
                    backgroundColor: '#a2cbff',
                    marginBottom: '5px',
                    minWidth: window.innerWidth > 978 ? '205px' : '170px',
                  }}
                  name={'filtersGroup'}
                  value={'paid'}
                  onChange={(value) => {
                    if (
                      (service.nearestPaidDate > service.nearestPsdfDate ||
                        service.nearestPaidDate === null) &&
                      service.nearestPsdfDate
                    ) {
                      setSelectedFilter(value + '?' + service.nearestPsdfDate);
                    } else if (service.nearestPaidDate) {
                      setSelectedFilter(value + '?' + service.nearestPaidDate);
                    } else {
                      setSelectedFilter(value + '?' + new Date());
                    }
                    setFilterStateRefresh((p) => p + 1);
                  }}
                >
                  Mokami laikai
                </Radio>
              )}

              {service.psdf && service.nearestPsdfDate && (
                <Radio
                  style={{
                    border:
                      selectedFilter.split('?')[0] === 'tlk'
                        ? '2px #4181d0 solid'
                        : '1px lightgrey solid',
                    borderRadius: '40px',
                    padding:
                      window.innerWidth > 978
                        ? '8px 10px 6px 10px'
                        : '8px 10px 8px 10px',
                    backgroundColor: '#CCF9E5',
                    marginBottom: '5px',
                    minWidth: window.innerWidth > 978 ? '205px' : '170px',
                  }}
                  name={'filtersGroup'}
                  value={'tlk'}
                  onChange={(value) => {
                    if (service.psdf && service.nearestPsdfDate) {
                      setSelectedFilter(value + '?' + service.nearestPsdfDate);
                    } else if (service.upcomingVisit) {
                      setSelectedFilter(value + '?' + service.upcomingVisit);
                    } else {
                      setSelectedFilter(value + '?' + new Date());
                    }
                    setFilterStateRefresh((p) => p + 1);
                  }}
                >
                  Kompensuoja PSDF
                  <Styled.TlkIconSvg>
                    <TlkIcon />
                  </Styled.TlkIconSvg>
                </Radio>
              )}
            </Styled.TimeSlotsFilter>
            <Calendar
              defaultData={
                service.upcomingVisit ? service.upcomingVisit : undefined
              }
              workplaceId={workplaceId}
              service={service}
              isHalfMrt={isHalfMrt}
              error={errors.timeSlotId}
              widget={false}
              filter={selectedFilter}
              refresh={refresh}
              refreshOfFilter={filterStateRefresh}
              dataRequest={getTimeSlots}
              psdfService={service.psdf}
              hasNearestPsdfDate={service.nearestPsdfDate !== null}
              extraPrice={service.extraPrice}
              servicePrice={service.priceWithDiscount}
              psdfPrice={getAdditionalPrice()}
              onChange={(timeSlot) => {
                const { timeSlotId } = timeSlot;

                setTimeSlotSelected(true);
                setFieldValue('timeSlotId', timeSlotId);
                setFieldValue('timeSlot', timeSlot);
                setSelectedTimeSlot(timeSlot);
              }}
              selected={
                values.timeSlot.duplicatedTimeSlotId === null
                  ? values.timeSlotId
                  : values.timeSlot.duplicatedTimeSlotId
              }
            />
          </div>
        ),
        onChange: async (values) => {
          const { timeSlotId, timeSlot } = values;
          if (lastStep === undefined) {
            const dateTimeNow = moment(new Date());
            const timeSlotEndTime = moment(selectedTimeSlot?.startDate);
            if (
              timeSlotEndTime.diff(dateTimeNow, 'minutes') <
              service.durationTime
            ) {
              setShowEmptyCartMessage(true);
            } else {
              await timeRequest.post('/Reservation', {
                timeSlotsIds: [timeSlotId],
                additionalData: `location:ServicePage.js onChange; historyLocation:${history.location.pathname}`, //for debuging purposes. Temporary po-706
              });

              if (timeResponse.status < 400) {
                dispatch({
                  type: 'SET_TEMP_TIME_SLOT',
                  timeSlotId,
                });

                dispatch({
                  type: 'SET_SERVICE_DURATION',
                  data: {
                    serviceDuration: service.durationTime,
                    timeSlotDate: selectedTimeSlot?.startDate,
                  },
                });

                userDispatch({
                  type: 'SET_TIMESLOT',
                  timeSlotId,
                });

                userDispatch({
                  type: 'SET_TIMESLOT_SERVICE_URL',
                  data: {
                    backUrl: `/order/${workplaceId}/${serviceId}`,
                  },
                });
                dispatch({
                  type: 'SET_TIMESLOT',
                  selectedTimeslot: timeSlot,
                });
                
                dispatch({
                  type: 'SET_TIMER',
                  date: new Date(),
                });

                return true;
              }
            }

            return false;
          }
          return true;
        },
      },

      {
        name: t('registration.form.tabName'),
        renderCondition: true,
        schema: () => {
          const schema = {};
          if (
            service.categoryId === 1 ||
            (service.categoryId === 2 && selectedTimeSlot.isPaid)
          ) {
            schema.patientComment = Yup.string().required(requiredMessage);
          }
          if (
            (service.serviceTypeExternalId ===
              +process.env.REACT_APP_KT_SERVICE_TYPE_ID ||
              service.serviceTypeExternalId ===
                +process.env.REACT_APP_MRT_SERVICE_TYPE_ID) &&
            (service.isFileRequired || isFileRequiredForService()) &&
            fileUploadRequired
          ) {
            schema.fileIds = Yup.array()
              .min(1, t('validation.referralToDoctorNotFamily'))
              .required(t('validation.referralToDoctorNotFamily'));
          } else if (
            (service.isFileRequired || isFileRequiredForService()) &&
            fileUploadRequired
          ) {
            schema.fileIds = Yup.array()
              .min(1, t('validation.referralToDoctor'))
              .required(t('validation.referralToDoctor'));
          }

          return schema;
        },
        content: (
          errors,
          values,
          setFieldValue,
          handleChange,
          setFieldError,
        ) => (
          <>
            <Form>
              <Form.Row>
                <Field
                  component={TextField}
                  autoFocus={underAge ? false : true}
                  textarea
                  name="patientComment"
                  label={t('registration.form.fieldPurposeLabel')}
                  error={errors.patientComment}
                  maxLength={1000}
                  onChange={handleChange}
                  // defaultValue={initialValues.patientComment}
                />
              </Form.Row>

              {service.specialtyExternalId != SGSpecialtyExternalId && (
                <Form.Row>
                  <Checkbox
                    value={values.eHealth}
                    name="eHealth"
                    onChange={(event) => {
                      if (event.target.checked) {
                        setFileUploadRequired(false);
                      } else {
                        setFileUploadRequired(true);
                      }

                      setFieldValue('eHealth', event.target.checked);
                      // values.eHealth = !values.eHealth;
                    }}
                  >
                    <span>Siuntimas yra e-Sveikata sistemoje</span>
                  </Checkbox>
                </Form.Row>
              )}
              <Form.Row>
                <FileUpload
                  request={fileRequest}
                  error={errors.fileIds}
                  onChange={(value) => {
                    if (value) {
                      setFieldError('fileIds', false);
                    }
                    setFieldValue('files', value);
                    setFieldValue(
                      'fileIds',
                      value.map((f) => f.fileUid),
                    );
                  }}
                  value={values.files}
                  label={t('registration.form.fieldFileLabel')}
                />
              </Form.Row>
            </Form>
          </>
        ),
      },
      {
        name: t('registration.questions.tabName'),
        renderCondition:
          (service.serviceTypeExternalId == sgkExternalServiceTypeId &&
            service.isSubscriptionDepartment &&
            selectedTimeSlot?.isPaid) ||
          (service.serviceTypeExternalId != sgkExternalServiceTypeId &&
            selectedTimeSlot &&
            (service?.serviceTypeExternalId?.toString() == mrtCategory ||
              selectedTimeSlot?.isPaid) &&
            !!(
              service.serviceQuestions?.length ||
              service.contraindicationQuestions?.length
            ) &&
            service.categoryId !== 3),
        schema: () => {
          return {
            serviceQuestionId: Yup.string()
              .nullable()
              .test(
                'serviceQuestionId',
                'Laukelis privalomas',
                function (value, { createError }) {
                  // if (
                  //   !(
                  //     service.serviceTypeExternalId ==
                  //       sgkExternalServiceTypeId && selectedTimeSlot?.isPaid
                  //   )
                  // ) {
                  //   return true;
                  // } else if (value === undefined || value === null) {
                  //   return false;
                  // }
                  return true; //in case it needs to be rewerted. But so far it is not needed to be required
                },
              ),
            contraindicationQuestionIds: Yup.array().max(
              0,
              t('validation.contraindicationQuestion', {
                phoneNumber,
                shortPhoneNumber,
              }),
            ),
          };
        },
        content: (errors, values, setFieldValue, handleChange) => (
          <Form>
            {selectedTimeSlot &&
              selectedTimeSlot?.isPaid &&
              !!service.serviceQuestions?.length && (
                <Form.Group
                  title={t('registration.questions.fieldServiceQuestionLabel')}
                  error={errors.serviceQuestionId}
                >
                  {service.serviceTypeExternalId == sgkExternalServiceTypeId &&
                    service.isSubscriptionDepartment &&
                    service.serviceQuestions
                      .filter((x) => x.questionId == 3 || x.questionId == 4)
                      .map((q) => (
                        <Form.Row key={q.questionId}>
                          <Radio
                            error={errors.serviceQuestionId}
                            name="serviceQuestionId"
                            value={q.questionId}
                            onChange={(value) => {
                              setFieldValue('serviceQuestionId', value);
                            }}
                          >
                            {q.question}
                          </Radio>
                        </Form.Row>
                      ))}

                  {service.serviceTypeExternalId != sgkExternalServiceTypeId &&
                    service.serviceQuestions
                      .filter((x) => x.questionId != 4)
                      .map((q) => (
                        <Form.Row key={q.questionId}>
                          <Radio
                            error={errors.serviceQuestionId}
                            name="serviceQuestionId"
                            value={q.questionId}
                            onChange={(value) => {
                              setFieldValue('serviceQuestionId', value);
                            }}
                          >
                            {q.question}
                          </Radio>
                        </Form.Row>
                      ))}
                </Form.Group>
              )}
            {!!service.contraindicationQuestions?.length && (
              <Form.Group
                title={t(
                  'registration.questions.fieldContraindicationQuestionLabel',
                )}
                error={errors.contraindicationQuestionIds}
              >
                {service.contraindicationQuestions.map((q) => (
                  <Form.Row key={q.questionId}>
                    <Checkbox
                      value={q.questionId}
                      name="contraindicationQuestionIds"
                      onChange={(event) => {
                        if (event.target.checked) {
                          setFieldValue('contraindicationQuestionIds', [
                            ...values.contraindicationQuestionIds,
                            q.questionId,
                          ]);
                        } else {
                          setFieldValue(
                            'contraindicationQuestionIds',
                            values.contraindicationQuestionIds.filter(
                              (v) => v !== q.questionId,
                            ),
                          );
                        }
                      }}
                      error={
                        errors.contraindicationQuestionIds &&
                        values.contraindicationQuestionIds.includes(
                          q.questionId,
                        )
                      }
                    >
                      {q.question}
                    </Checkbox>
                  </Form.Row>
                ))}

                <Form.Row>
                  <Styled.ServiceContraImportant>
                    <strong>SVARBU! </strong>Tyrimo atlikimas turint bent vieną
                    kontraindikaciją gali būti pavojingas žmogaus sveikatai ar
                    net gyvybei. Jei turite klausimų dėl kontraindikacijų,
                    prašome susisiekti tel. {shortPhoneNumber} arba{' '}
                    {phoneNumber}.
                  </Styled.ServiceContraImportant>
                </Form.Row>
              </Form.Group>
            )}
          </Form>
        ),
      },
      {
        name: t('registration.consultationType.tabName'),
        renderCondition:
          !isNull(service?.secondaryPrice) &&
          !selectedTimeSlot?.isExtra &&
          selectedTimeSlot?.isPaid,

        schema: () => {
          return {
            primaryVisit: Yup.boolean().required(requiredMessage),
          };
        },
        content: (errors, values, setFieldValue, handleChange) => (
          <Form>
            <Form.Group
              title={t('registration.consultationType.title')}
              error={errors.primaryVisit}
            >
              <Form.Row>
                <Radio
                  name="primaryVisit"
                  value={true}
                  checked={values.primaryVisit === true}
                  onChange={(value) => {
                    setFieldValue('primaryVisit', value);
                  }}
                >
                  <Trans
                    i18nKey="registration.consultationType.primaryVisit"
                    values={{
                      price: formatCurrency(service.priceWithDiscount),
                    }}
                    components={{ small: <small /> }}
                  />
                </Radio>
              </Form.Row>
              <Form.Row>
                <Radio
                  name="primaryVisit"
                  value={false}
                  checked={values.primaryVisit === false}
                  onChange={(value) => {
                    setFieldValue('primaryVisit', value);
                  }}
                >
                  {(isDiscount && (
                    <Trans
                      i18nKey="registration.consultationType.secondaryVisit"
                      values={{
                        price: formatCurrency(service.secondaryDiscountEur),
                      }}
                      components={{ small: <small /> }}
                    />
                  )) || (
                    <Trans
                      i18nKey="registration.consultationType.secondaryVisit"
                      values={{ price: formatCurrency(service.secondaryPrice) }}
                      components={{ small: <small /> }}
                    />
                  )}
                </Radio>
              </Form.Row>
            </Form.Group>
          </Form>
        ),
      },
    ];
  }, [
    t,
    service.serviceQuestions,
    service.contraindicationQuestions,
    service.secondaryPrice,
    service.upcomingVisit,
    service.serviceTypeExternalId,
    service.isFileRequired,
    service.priceWithDiscount,
    service.price,
    service.secondaryDiscountEur,
    selectedFilter,
    refresh,
    getTimeSlots,
    lastStep,
    timeRequest,
    timeResponse.status,
    dispatch,
    userDispatch,
    fileRequest,
    agreements.personalData.url,
    selectedTimeSlot,
    phoneNumber,
    shortPhoneNumber,
    isDiscount,
    setService,
    setFileUploadRequired,
    fileUploadRequired,
    underAge,
  ]);

  function getAdditionalPrice() {
    let sum = 0;
    if (!isMrtWorkplace()) return sum;
    service?.additionalServices?.forEach((as) => {
      sum += as.price;
    });
    return sum;
  }

  return (
    <Page loading={loading}>
      <Container>
        <Page.Title
          backUrl={
            servicesPageQueryUrl
              ? `/services/${service.categoryId}/${service.serviceTypeId}` +
                servicesPageQueryUrl
              : null
          }
          goBack={!servicesPageQueryUrl}
        >
          {t('common.registerToVisit')}
          <small>{service.serviceTypeTitle}</small>
        </Page.Title>
        <Description
          service={service}
          step={step}
          isExtraTimeSlot={selectedTimeSlot?.isExtra}
          isPsdfTimeSlot={!selectedTimeSlot?.isPaid}
          is3T={
            (new Date(selectedTimeSlot?.startDate) >=
              mrt3tPriceChangeDate.getTime() &&
              service?.serviceTitle?.includes('3T') &&
              workplacesFor3T.includes(workplaceId.toString())) ||
            workplaceId === process.env.REACT_APP_3TWORKPLACEID
          }
          isHalfMrt={isHalfMrt}
          otherServices={otherServices}
          selectedTimeslot={selectedTimeSlot}
          isLaboratoryServices={isLaboratoryServices()}
          laboratoryMultipleServices={laboratoryMultipleServices?.slice(1)}
          removeFromLaboratoryMultipleServicesCallback={
            handleRemoveFromLaboratoryMultipleServices
          }
          addMoreLaboratoryServicesButton={<AddMoreLaboratoryServicesButton />}
        />

        {Array.isArray(service.servicePreparations) &&
          service.servicePreparations.length > 0 && (
            <ServicePreparation service={service} />
          )}

        {showExpressCareTypes && (
          <Tabs
            active={step}
            ref={tabsRef}
            actions={
              <Form.Actions>
                {
                  <Button
                    size="large"
                    variant="secondary"
                    onClick={() =>
                      step === 0
                        ? servicesPageQueryUrl
                          ? history.push(
                              `/services/${service.categoryId}/${service.serviceTypeId}` +
                                servicesPageQueryUrl,
                            )
                          : history.push(
                              `/services/${service.categoryId}/${service.serviceTypeId}`,
                            )
                        : changeStep()
                    }
                  >
                    Atgal
                  </Button>
                }
              </Form.Actions>
            }
          >
            <Tabs.List>
              {expressCareTabConfig().map((tab) => (
                <Tabs.Tab key={tab.name}>{tab.name} </Tabs.Tab>
              ))}
              <Styled.TimeSlotLegend></Styled.TimeSlotLegend>
            </Tabs.List>

            {expressCareTabConfig().map((tab) => (
              <Tabs.Content key={tab.name}>{tab.content()}</Tabs.Content>
            ))}
          </Tabs>
        )}
        {!showExpressCareTypes && (
          <Formik
            innerRef={formRef}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape(
              getTabsConfig()[step]?.schema(),
            )}
            initialValues={
              previousRegistrationService === undefined ||
              previousRegistrationService === null
                ? initialValues
                : previousRegistrationService
            }
            onSubmit={addToCart}
            validate={saveFromValues}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              handleSubmit,
              errors,
              setFieldError,
              validateForm,
              initialValues,
            }) => {
              const tabs = getTabsConfig(
                errors,
                values,
                setFieldValue,
                handleChange,
                setFieldError,
                initialValues,
              ).filter((t) => t.renderCondition);
              return (
                <div
                  onChange={() => {
                    saveFromValues(values);
                  }}
                >
                  <Tabs
                    active={step}
                    ref={tabsRef}
                    actions={
                      <Form.Actions>
                        {
                          <Button
                            size="large"
                            variant="secondary"
                            onClick={() =>
                              step === 0
                                ? servicesPageQueryUrl
                                  ? history.push(
                                      `/services/${service.categoryId}/${service.serviceTypeId}` +
                                        servicesPageQueryUrl,
                                    )
                                  : history.push(
                                      `/services/${service.categoryId}/${service.serviceTypeId}`,
                                    )
                                : changeStep()
                            }
                          >
                            Atgal
                          </Button>
                        }

                        {step === tabs.length - 1 ? (
                          <Button
                            size="large"
                            onClick={() => {
                              setActiveTabs(tabs);
                              handleSubmit();
                            }}
                            type="submit"
                          >
                            Tęsti
                          </Button>
                        ) : (
                          <Button
                            size="large"
                            disabled={isButtonLoading || !isTimeSlotSelected}
                            onClick={() => {
                              if (step === 0) {
                                exponeaCapture(
                                  [
                                    {
                                      name: 'booking_appointment_date',
                                      value: selectedTimeSlot.startDate,
                                    },
                                    {
                                      name: 'booking_appointment_id',
                                      value: selectedTimeSlot.timeSlotId,
                                    },
                                    {
                                      name: 'booking_stage',
                                      value: 'Booking process started',
                                    },
                                  ],
                                  'booking_start',
                                );
                              }

                              changeStep({
                                next: true,
                                validateForm,
                                values,
                                validate: true,
                              });
                            }}
                          >
                            Toliau
                          </Button>
                        )}
                      </Form.Actions>
                    }
                  >
                    <Tabs.List>
                      {tabs.map((tab) => (
                        <Tabs.Tab key={tab.name}>{tab.name} </Tabs.Tab>
                      ))}
                      <Tabs.Tab key={'Įveskite reikiamus duomenis'}>
                        Įveskite reikiamus duomenis
                      </Tabs.Tab>
                      {selectedTimeSlot?.isPaid && (
                        <Tabs.Tab key={'Apmokėkite užsakymą'}>
                          Apmokėkite užsakymą
                        </Tabs.Tab>
                      )}
                      {selectedTimeSlot?.isPaid === false && (
                        <Tabs.Tab key={'Patvirtinkite užsakymą'}>
                          Patvirtinkite užsakymą
                        </Tabs.Tab>
                      )}
                    </Tabs.List>

                    {tabs.map((tab) => (
                      <Tabs.Content key={tab.name}>
                        {tab.content(
                          errors,
                          values,
                          setFieldValue,
                          handleChange,
                          setFieldError,
                        )}
                      </Tabs.Content>
                    ))}
                  </Tabs>
                </div>
              );
            }}
          </Formik>
        )}
      </Container>

      <Modal visible={timeRequest.error} onClose={() => history.push('/')}>
        <Modal.Alert type="error">
          {timeResponse.status >= 400 &&
          timeResponse.data?.detail !== 'Slot Is Reserved'
            ? 'Atsiprašome, įvyko klaida'
            : 'Pasirinktas laikas jau užimtas'}
        </Modal.Alert>
        <Modal.TextContent>
          {timeResponse.status >= 400 &&
          timeResponse.data?.detail !== 'Slot Is Reserved'
            ? 'Registracija vizitui nerasta. Bandykite ieškoti kito vizito'
            : 'Apgailestaujame, tačiau šį laiką jau rezervavo kitas klientas. Pasirinkite kitą laiką.'}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              timeResponse.status >= 400 ? history.go(0) : history.push('/');
            }}
          >
            Rinktis kitą vizitą
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={showEmptyCartMessage}
        onClose={() => setShowEmptyCartMessage(false)}
      >
        <TimeSlotSessionStyled.Message block>
          {t('timeSession.expiredModal.title')}
        </TimeSlotSessionStyled.Message>
        <Modal.TextContent>
          {t('timeSession.expiredModal.description')}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setShowEmptyCartMessage(false);
              refreshPage();
            }}
          >
            {t('timeSession.expiredModal.closeButton')}
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={cartState.leftToPaysera}
        showClose={false}
        onClose={() => {
          setIsCancelModalVisible(false);
          dispatch({
            type: 'REMOVE_LEFTTOPAYSERA',
          });
          dispatch({
            type: 'RESET_PAYSERA_URL',
          });
          userDispatch({
            type: 'SET_LEFT_CART_PAGE',
            left: false,
          });
        }}
      >
        <Modal.TextContent>
          Ar tikrai norite atšaukti registraciją?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              dispatch({ type: 'SET_LEFTTOPAYSERA' });
              setIsCancelRegistration(false);
              setIsCancelModalVisible(false);
              window.location.replace(cartState.payseraUrl);
            }}
          >
            Grįžti
          </Button>
          <Button
            size="large"
            onClick={() => {
              setIsCancelModalClicked(true);
              setIsCancelRegistration(true);
              setIsCancelModalVisible(false);
              userDispatch({
                type: 'SET_LEFT_CART_PAGE',
                left: false,
              });
              dispatch({
                type: 'REMOVE_LEFTTOPAYSERA',
              });

              dispatch({
                type: 'RESET_CART',
              });
            }}
          >
            Taip
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal visible={showExpressCareModal} showClose={false}>
        <Modal.Alert type="warning" style={{ padding: '15px' }}>
          <div style={{ maxWidth: '330px', textAlign: 'center' }}>
            Express Care® by Affidea – tai operatyviai suteikiama medicininė
            pagalba, esant lengviems galūnių ir minkštųjų audinių sužalojimams.
          </div>
        </Modal.Alert>
        <div style={{ maxWidth: '350px', textAlign: 'center' }}>
          <a href="https://affidea.lt/express-care/">Daugiau informacijos</a>
        </div>
        <Modal.Alert type="warning" style={{ padding: '15px' }}>
          <div style={{ maxWidth: '330px', textAlign: 'center' }}>
            Nereikėtų kreiptis į Express Care® šiais atvejais.
            <br />
            Krūtinės skausmas, sunkios traumos, atviri lūžiai, sunkus dusulys,
            pilvo skausmas, su nėštumu susijusi liga, ginekologijos skundai.
          </div>
        </Modal.Alert>
        <Modal.TextContent>
          <div style={{ maxWidth: '330px', textAlign: 'left' }}>
            Jei pasireiškė ūmios klinikinės būklės, kai gresia pavojus paciento
            ir (ar) aplinkinių gyvybei arba kai pagalbos nesuteikimas laiku
            sukeltų sunkių komplikacijų grėsmę pacientams.
            <strong>
              {' '}
              Šiais atvejais kuo skubiau skambinkite 112 arba kreipkitės į
              artimiausios ligoninės skubiosios pagalbos skyrių.
            </strong>{' '}
            Taip pat nereikėtų kreiptis, kai turite siuntimą gydytojo
            specialisto konsultacijai ir/ar tyrimui.
            <br />
            <br />
            <strong>Pastaba:</strong>
            <br />
            <br />
            Esant būtinybei gydytojas gali nukreipti pacientą į kitą gydymo
            įstaigą. Už Express Care® paslaugas galima atsiskaityti ir privačiu
            sveikatos draudimu. Dėl apmokėjimo privataus sveikatos draudimo
            lėšomis sąlygų maloniai kviečiame teirautis Jūsų draudimo atstovų.
          </div>
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="medium"
            onClick={() => {
              setShowExpressCareModal(false);
            }}
          >
            Supratau
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default ServicePage;
